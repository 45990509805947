import "new-event-polyfill";
import 'classlist-polyfill';
import 'core-js/features/symbol';
import 'core-js/features/aggregate-error';
import 'core-js/features/array';
import 'core-js/features/array-buffer';
import 'core-js/features/date';
import 'core-js/features/function';
import 'core-js/features/global-this';
import 'core-js/features/json';
import 'core-js/features/map';
import 'core-js/features/math';
import 'core-js/features/number';
import 'core-js/features/object';
import 'core-js/features/reflect';
import 'core-js/features/set';
import 'core-js/features/promise';
import 'core-js/features/regexp';
import 'core-js/features/string';
import 'core-js/features/typed-array';
import 'core-js/features/weak-map';
import 'core-js/features/weak-set';
import 'core-js/features/url-search-params';
import 'core-js/features/url';
import 'core-js/web';
import 'core-js/proposals';
import 'style-scoped';
import 'semantic-ui-css/semantic.min.css'
import * as React from 'react'
import ReactDOM from 'react-dom';
import axios from 'axios';
import { initializeIcons } from "@fluentui/react";

// import image files needed for index.html
import { PMProgressIndicator } from "components/SimpleComponents/PMProgressIndicator";

initializeIcons();

export const authenticatedAxios = axios.create({ withCredentials: true })
authenticatedAxios.interceptors.request.use(
    request => {
        return request
    },
    error => {
        return Promise.reject(error)
    }
)

const Entry = React.lazy(/* webpackChunkName: "entry" */() => import("./entry"));

export const Index: React.FC = () => {
    return (
        <React.Suspense fallback={<PMProgressIndicator />}>
            <Entry />
        </React.Suspense>
    )
}

ReactDOM.render(<Index />, document.getElementById("root"));